export default {
    name: "DecksAndSlides",
    created() {
        this.$nextTick(function() {
            this.ariseCombobox('#comboboxTotal')
            this.GetEducation()
        });
    },
    mounted() {
        this.$nextTick(function() {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
            dataArr: [],
            showDataArr: [],
            searchArr: [],
            showsearchArr: [],
            searchArrulF: false,
            inputText: '',
            inputTextlog: '',
            arrT: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取数据
        GetEducation() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetEducation",
                data: { token: "webkey", lang: localStorage.getItem('lang') || 1 },
                success: (data) => {
                    if (data.status == 'SUCCESS') {
                        this.searchArr = data.text
                        this.showsearchArr = data.text
                        this.dataArr = data.text
                    }
                },
                error: function(XMLHttpRequest) {}
            });
        },
        // 跳转详细页面
        toDetailPage: function(url) {
            window.open(url);
        },
        // Warrant Code 固定
        WarrantCodeFixation: function() {
            $('#TermSheet').find('.table_box').scroll(function() {
                if ($(this).scrollTop() > 0) {
                    $('.CodeFixationTableBox').show()
                } else {
                    $('.CodeFixationTableBox').hide()
                }
                $('.CodeFixationTableBox').width($('#TermSheet').find('.table_box').outerWidth(true) - 5)
            })
        },
        // 出现combobox
        ariseCombobox: function(id, index) {
            let _this = this
            this.$$.jqcombobox(id)
            $(id).combobox({
                select: function(event, ui) {
                    _this.dataArr = [JSON.parse(this.value)]
                }
            });
            $(id).parent().find('input').attr('placeholder', 'Search Title')
            _this.$$.inputEmpty($(id).parent())
        },
    }
};